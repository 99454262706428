import { render, staticRenderFns } from "./receivecoupon.vue?vue&type=template&id=3bfdfc34&scoped=true"
import script from "./receivecoupon.vue?vue&type=script&lang=js"
export * from "./receivecoupon.vue?vue&type=script&lang=js"
import style0 from "./receivecoupon.vue?vue&type=style&index=0&id=3bfdfc34&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bfdfc34",
  null
  
)

component.options.__file = "receivecoupon.vue"
export default component.exports